<template>
    <v-row class="mt-5">
    <background page="contact"></background>
        <v-col class="px-5" cols="12">
            <v-card flat class="rounded-xl py-5 shadow">
                <v-card-text v-if="showSuccess" class="text-center">
                    <v-icon size="75" class="petu-dark-green mb-3">mdi-check-circle</v-icon>
                    <div>Su mensaje ha sido enviado exitosamente.</div>
                    <v-btn color="blue darken" class="no-uppercase" text @click="showSuccess = false">Enviar nuevo mensaje</v-btn>
                </v-card-text>

                <div v-else>
                    <v-card-title class="justify-center petu-green">Contáctanos</v-card-title>
                    <v-card-text class="text-left pb-0">Si tienes algún problema haciendo tus reservaciones, nos puedes enviar un mensaje con los días y horas que necesites reservar.</v-card-text>
                    <v-card-text class="text-left pb-0">Por favor, sea paciente, a veces hay mucho flujo de mensajes. Le responderemos en las próximas 48 horas. ¡Gracias!</v-card-text>
                    <v-card-text class="text-left">Si necesitas información adicional o tienes duda, puedes llenar el siguiente formulario.</v-card-text>

                    <v-card-text class="pt-0">
                        <v-form v-model="valid" ref="contactUs">
                            <v-text-field v-model="contactForm.name" :rules="rules"  label="Nombre" placeholder="Nombre"></v-text-field>
                            <v-text-field v-model="contactForm.email" :rules="emailRules" label="Email" type="email" placeholder="Email" ></v-text-field>
                            <v-textarea   v-model="contactForm.message" :rules="rules" label="Mensaje" placeholder="Mensaje" rows="3"></v-textarea>
                            <v-btn :disabled="!valid" depressed @click="saveContactForm" :loading="loading" block class="petu-pink-bg white--text rounded-pill mt-2 no-uppercase">Enviar</v-btn>
                        </v-form>
                    </v-card-text>
                </div>

                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="9" class="text-left">
                                <div><a class="petu-green" style="text-decoration: none;" href="tel:9394896563">939.489.6563</a></div>
                                <div><a class="petu-green" style="text-decoration: none;" href="mailto:petu.power20@gmail.com">petu.power20@gmail.com</a></div>
                            </v-col>
                            <v-col cols="3" class="text-right"><v-img src="@/assets/IG-01.svg"></v-img></v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import Background from '@/components/Background'
import { ContactForm } from '@/classes/contactForm.js'
import { _st } from '@/softech'
import { mapGetters } from 'vuex'


export default {
	name		: 'ContactUs',
	components	: { Background },
    data: () => ({
        valid:          true,
        loading         : false,
        showSuccess    : false,
        contactForm    : { name: '', email: '', message : '' },

        rules: [v => !!v || 'Campo es requerido'],
        emailRules: [
            v => !!v || 'El email es requerido',
            v => !!_st.isEmail(v) || 'El email tiene que ser válido'
        ],
    }),
    beforeMount() {
        if( this.isLoggedIn && this.customer ) {
            this.contactForm.name = `${this.customer.firstName} ${this.customer.lastName}`;
            this.contactForm.email = this.customer.email;
        }
    },
    methods: {
        async saveContactForm() {
            if( !this.$refs.contactUs.validate() ) 
                return;

            try {
                let contactForm  = new ContactForm( this.contactForm );

                this.loading = true;
                await contactForm.save();
                this.showSuccess = true;
                this.contactForm.message = '';
            }
            catch( error ) {
                this.$root.$emit( 'alert', { status: 'error', message: 'No se pudo enviar mensaje' } );
            }
            finally {
                this.loading = false;
            }
        },
    },
    computed: {
        ...mapGetters({ customer: 'auth/customer', isLoggedIn: 'auth/isLoggedIn' }),
    }
}
</script>

<style scoped>
.shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}
</style>